<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {fetchProductsUomsApi,fetchProductsCategoriesApi,fetchWarehousesApi, fetchContractsListApi, fetchProductDetailsApi} from '@/api/common';
import {
  required,
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouveau produit",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  import :{fetchProductsUomsApi,fetchProductsCategoriesApi,fetchWarehousesApi, fetchContractsListApi, fetchProductDetailsApi},
  data() {
    return {
      title: "Nouveau produit",
      uoms: [],
      categories: [],
      waerhouses: [],
      contracts: [],
      items: [
        {
          text: "Stock",
          href: "/"
        },
        {
          text: "Produits",
          href: "/"
        },
        {
          text: "Nouveau",
          active: true
        }
      ],
      product: [],
      productForm: {
        uuid: "",
        name: "",
        unit_price: "",
        uom_id: "",
        contract_id: "",
        warehouse_id: "",
        category_id: "",
        description: "",
        barcode: "",
        available_in_requests: "",
      },
      
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    productForm: {
      name: { required },
      unit_price: { required },
    },
    
  },
  created: function(){
    this.fetchContracts();
    this.fetchProductsUoms();
    this.fetchProductsCategories();
    this.fetchWarehouses();
    this.fetchProductDetails();
  },
  methods: {
    fetchProductDetails(){
      var _this = this;
      fetchProductDetailsApi(this.$route.params.uid)
      .then((res) => {
        var prod = res.data.original.data;
        _this.productForm.uuid = prod.uuid;
        _this.productForm.name = prod.name;
        _this.productForm.unit_price = prod.unit_price;
        _this.productForm.uom_id = prod.uom;
        _this.productForm.contract_id = prod.contract;
        _this.productForm.warehouse_id = prod.warehouse;
        _this.productForm.category_id = prod.category;
        _this.productForm.description = prod.description;
        _this.productForm.barcode = prod.barcode;
        _this.productForm.available_in_requests = prod.available_in_requests;
      })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchContracts(){
      fetchContractsListApi()
      .then((res) => (this.contracts = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchProductsUoms(){
      fetchProductsUomsApi()
      .then((res) => (this.uoms = res.data.original.data))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchProductsCategories(){
      fetchProductsCategoriesApi()
      .then((res) => (this.categories = res.data.original.data))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchWarehouses(){
      fetchWarehousesApi()
      .then((res) => (this.waerhouses = res.data.original.data))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http.post('/stock/products/update', this.productForm)

      .then((res) => {
          var status = res.data.original.status;
          switch(status){
            case 200: 
              this.$toast.success(res.data.original.msg);
              router.push({ name: 'base.stock.products' });
              
            break;

            case 500: 
              this.$toast.warning(res.data.original.msg);
            break;
          }
      })
      .catch((error) => {
          this.$toast.error(error.message);
      }).finally(() => {
      });
    },

   
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Désignation *</label>
                    <input
                      id="deptName"
                      v-model="productForm.name"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.productForm.name.$error }"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Code a barre</label>
                    <input
                      id="deptName"
                      v-model="productForm.barcode"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Prix unitaire *</label>
                    <input
                      id="deptName"
                      v-model="productForm.unit_price"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom02">Contrat</label>
                    <multiselect v-model="productForm.contract_id" :searchable="true" track-by="id" label="ref" :options="contracts" placeholder="Contrat" :allow-empty="false">
                      <template slot="singleContract" slot-scope="{ contract }">{{ contract.id }}</template>
                    </multiselect>
                    
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom02">Catégorie</label>
                    <multiselect v-model="productForm.category_id" :searchable="true" track-by="id" label="name" :options="categories" placeholder="Catégorie" :allow-empty="false">
                      <template slot="singleCateg" slot-scope="{ category }">{{ category.name }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom02">Magasin</label>
                    <multiselect v-model="productForm.warehouse_id" :searchable="true" track-by="id" label="name" :options="waerhouses" placeholder="Magasin" :allow-empty="false">
                      <template slot="singleWarehouse" slot-scope="{ warehouse }">{{ warehouse.name }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom02">Unité de mesure</label>
                    <multiselect v-model="productForm.uom_id" :searchable="true" track-by="id" label="name" :options="uoms" placeholder="Contrat" :allow-empty="false">
                      <template slot="singleUom" slot-scope="{ uom }">{{ uom.name }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <b-form-checkbox
                      v-model="productForm.available_in_requests"
                      :checked="productForm.available_in_requests"
                      switch
                      class="mb-3"
                      >Disponible dans les requêtes</b-form-checkbox
                    >
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Commentaires</label>
                    <textarea class="form-control" id="" cols="30" rows="10"></textarea>
                    
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" :disabled="$v.productForm.$invalid" type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>