var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Désignation *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.name,
                              expression: "productForm.name"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.productForm.name.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.productForm.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.productForm,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Code a barre")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.barcode,
                              expression: "productForm.barcode"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.productForm.barcode },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.productForm,
                                "barcode",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Prix unitaire *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.unit_price,
                              expression: "productForm.unit_price"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.productForm.unit_price },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.productForm,
                                "unit_price",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Contrat")]
                          ),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "ref",
                              options: _vm.contracts,
                              placeholder: "Contrat",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleContract",
                                fn: function(ref) {
                                  var contract = ref.contract
                                  return [_vm._v(_vm._s(contract.id))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.productForm.contract_id,
                              callback: function($$v) {
                                _vm.$set(_vm.productForm, "contract_id", $$v)
                              },
                              expression: "productForm.contract_id"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Catégorie")]
                          ),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.categories,
                              placeholder: "Catégorie",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleCateg",
                                fn: function(ref) {
                                  var category = ref.category
                                  return [_vm._v(_vm._s(category.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.productForm.category_id,
                              callback: function($$v) {
                                _vm.$set(_vm.productForm, "category_id", $$v)
                              },
                              expression: "productForm.category_id"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Magasin")]
                          ),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.waerhouses,
                              placeholder: "Magasin",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleWarehouse",
                                fn: function(ref) {
                                  var warehouse = ref.warehouse
                                  return [_vm._v(_vm._s(warehouse.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.productForm.warehouse_id,
                              callback: function($$v) {
                                _vm.$set(_vm.productForm, "warehouse_id", $$v)
                              },
                              expression: "productForm.warehouse_id"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "validationCustom02" } },
                            [_vm._v("Unité de mesure")]
                          ),
                          _c("multiselect", {
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.uoms,
                              placeholder: "Contrat",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleUom",
                                fn: function(ref) {
                                  var uom = ref.uom
                                  return [_vm._v(_vm._s(uom.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.productForm.uom_id,
                              callback: function($$v) {
                                _vm.$set(_vm.productForm, "uom_id", $$v)
                              },
                              expression: "productForm.uom_id"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              checked: _vm.productForm.available_in_requests,
                              switch: ""
                            },
                            model: {
                              value: _vm.productForm.available_in_requests,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.productForm,
                                  "available_in_requests",
                                  $$v
                                )
                              },
                              expression: "productForm.available_in_requests"
                            }
                          },
                          [_vm._v("Disponible dans les requêtes")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "validationCustom02" } }, [
                          _vm._v("Commentaires")
                        ]),
                        _c("textarea", {
                          staticClass: "form-control",
                          attrs: { id: "", cols: "30", rows: "10" }
                        })
                      ])
                    ])
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.$v.productForm.$invalid,
                        type: "submit"
                      }
                    },
                    [_vm._v("Enregistrer")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }